<template>
  <v-toolbar
    color="white"
    class="elevation-0"
    style="border-bottom:1px solid #e6e5e7 !important;"
    clipped-right
    clipped-left
    app
  >
    <v-toolbar-title>
      <router-link
        :to="userIsLoggedIn ? {name: 'candidate:offers'} : {name: 'candidate:landing'}"
      >
        <img
          src="@/assets/logo-MM-PDE-transparente.png"
          height="50px"
        >
      </router-link>
    </v-toolbar-title>

    <v-spacer />

    <ToolbarLinksDesktop v-if="$vuetify.breakpoint.mdAndUp" />
    <ToolbarLinksMobile v-else />
  </v-toolbar>
</template>

<script>
import ToolbarLinksDesktop from '@/components/candidates/ToolbarLinksDesktop.vue';
import ToolbarLinksMobile from '@/components/candidates/ToolbarLinksMobile.vue';
import { JwtIsExpired } from '@/utils/jwt';

export default {
  name: 'Toolbar',
  components: {
    ToolbarLinksMobile,
    ToolbarLinksDesktop,
  },
  computed: {
    userIsLoggedIn() {
      return !JwtIsExpired(this.$store.state.jwt);
    },
  },
};
</script>
